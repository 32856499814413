import React, { useState, useEffect, useCallback, FC, ChangeEvent, useRef, useMemo } from 'react'
import { NewCheckbox, NewButton, Select, Item, AutocompleteInput, FLOW_STATUS, NotificationType, ROLES, ROLE_NAME_INITIALS, ADMIN_PORTAL_ROLES, ROLE_NAME } from 'prace-common-components'
import { $Container, $ModalContainer, $Grid } from './styles'
import { AssignModalProps } from './types'
import Grid from '@material-ui/core/Grid'
import { useLoadUserSuggestionsMutation } from 'store/api/users'
import { UserSuggestion } from 'store/users/types'
import { useAppDispatch } from 'store/hooks'

export const AssignModal: FC<AssignModalProps> = ({
  assignable = [],
  handleAssign,
  handleCancel,
  applicationId,
  activeCutOff,
  initialPartition,
  assignmentOwnersStatus,
  canHaveLead,
  loading,
}) => {
  const dispatch = useAppDispatch()
  const resources = activeCutOff?.resources ? activeCutOff.resources : []
  /* Remove resources that are not from the active cutoff and selected partition */
  const allowedResourcePartitions = resources.filter((resource) => !resource.partition.disabled)
  const partitionOptions: Item[] =  [...new Set(allowedResourcePartitions.map((resource) => ({
    label: resource.partition.name, value: resource.partition.id,
  } as Item)))] || []

  /* Sort partitions by name */
  const sortedPartitionOptions = partitionOptions?.sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  }) || []

  const defaultPartition = initialPartition ? String(initialPartition.id) : null

  const filteredAssignableRoles = useMemo(() => assignable.filter(
    (role) => ![...ADMIN_PORTAL_ROLES, ROLES.USER].includes(role)), [assignable])
    
  const [partitionId, setPartitionId] = useState<Nullable<string>>(defaultPartition)
  const [userItem, setUserItem] = useState<Item>()
  const [role, setRole] = useState<Nullable<ROLES>>(
    filteredAssignableRoles.length ? filteredAssignableRoles[0] : null)
  const [makeLead, setMakeLead] = useState<boolean>(false)
  const searchTimer = useRef<number>()
  const [userSuggestions, setUserSugestions] = useState<UserSuggestion[]>([])
  const [loadUserSuggestions] = useLoadUserSuggestionsMutation()

  const getUsers = useCallback(async (search = '') => {
    try {
      const data = await loadUserSuggestions({ 
        search,
        roles: filteredAssignableRoles,
        applicationId,
        page: 0,
        pageSize: 20,
      }).unwrap()
      setUserSugestions(data.users || [])
    } catch (err) {
      console.log(err)
    }
  }, [loadUserSuggestions, applicationId, filteredAssignableRoles])

  useEffect(() => {
    getUsers('')
  }, [getUsers])

  const onInputChange = (_: any, value: string) => {
    clearTimeout(searchTimer.current)
    if(filteredAssignableRoles.length) {
      try {
        searchTimer.current = window.setTimeout(async () => {
          await getUsers(value)
        }, 500)
      } catch (err) {
        console.log(err)
      }
    }
  }

  const handleAddAssign = () => {
    if(userItem && role) {
      /* Check if this user already has ongoing assignments assigned by someone */
      if(assignmentOwnersStatus.some((a) => 
        !(a.assignerId === null || a.assignerId === undefined) && a.ownerId == Number(userItem.value) && 
          a.partitionId == partitionId &&
          ![FLOW_STATUS.REFUSED, FLOW_STATUS.REJECTED, FLOW_STATUS.REVOKED].includes(a.status),
      )) {
        dispatch({ type: 'notification', payload: { type: NotificationType.error, msg: `This user already has an ongoing assignment${partitionId ? ' for this partition' : ''}` } })
        return
      }
      handleAssign(Number(userItem.value), partitionId, makeLead, role)
    }
  }

  const options: Item[] = userSuggestions.map((suggestion) => ({
    label: `${suggestion.firstName || ''} ${suggestion.lastName || ''} - ${suggestion.email || ''} ${suggestion.role ? `(${ROLE_NAME_INITIALS[suggestion.role as ROLES || ROLES.USER]})` : ''}`,
    value: suggestion.id,
  })) || []

  const today = new Date()
  today.setDate(today.getDate() + 1)

  const userCurrentRole = userSuggestions.find((o) => o.id == userItem?.value)?.role

  const roleNameItems: Item[] = (filteredAssignableRoles || []).map((role) => ({ label: ROLE_NAME[role], value: role }))

  /* Only users that are CCRs (from a center) are allowed to have this role */
  const filteredRoleNameItems: Item[] = userCurrentRole === ROLES.COMPUTE_CENTRE_REPRESENTATIVE ?
    roleNameItems.filter((role) => role.value === ROLES.COMPUTE_CENTRE_REPRESENTATIVE) : 
    roleNameItems.filter((role) => role.value !== ROLES.COMPUTE_CENTRE_REPRESENTATIVE)

  return (
    <$ModalContainer>
      <>
        <$Grid container>
          <Grid item xs={12}>
            <AutocompleteInput
              title='User search'
              name='assign'
              required
              items={options}
              value={userItem}
              onChange={(_: ChangeEvent<unknown>, user: unknown) => {
                const userRole = userSuggestions.find((o) => o.id == (user as Item).value)?.role
                if(userRole) setRole(userRole as ROLES)
                else setRole(null)
                setUserItem(user as Item)
              }}
              onInputChange={onInputChange}
            />
          </Grid>
          {(filteredAssignableRoles.length > 1) && <Grid item xs={12}>
            <Select
              required
              disabled={!!userCurrentRole}
              title='Which role will the user have?'
              name='role'
              value={role}
              items={filteredRoleNameItems}
              onChange={(_name, value) => setRole(value as ROLES)}
            />
          </Grid>}
          <Grid item xs={12}>
            <Select
              title='Select Partition'
              name='partitionId'
              value={partitionId}
              items={sortedPartitionOptions}
              onChange={(_name: string, value: Nullable<string>) => setPartitionId(value)}
            />
          </Grid>
          {canHaveLead && <Grid item xs={12}>
            <NewCheckbox
              name='makeLead'
              value={makeLead}
              hideItalic
              onChange={(_, value: boolean) => setMakeLead(value)}
              title='Make this assignment lead'
            />
          </Grid>}
        </$Grid>
        <$Container container justifyContent='space-between'>
          <Grid item xs={3}>
            <NewButton loading={loading} onClick={handleAddAssign}>Assign</NewButton>
          </Grid>
          <Grid item xs container justifyContent='flex-end'>
            <NewButton variant='outlined' onClick={handleCancel}>Cancel</NewButton>
          </Grid>
        </$Container>
      </>
    </$ModalContainer>
  )
}
