import React from 'react'
import * as Sentry from '@sentry/react'
import { Routes, Route } from 'react-router-dom'
import { __PROD__, Routes as Routing } from 'constants/global'
import { NotFound404 } from 'prace-common-components'
import { Confirmation } from 'pages/Landing/views'
import { ForgotPassword } from 'pages/Landing/views/ForgotPassword'
import { Profile } from 'pages/Profile'
import { Home } from 'pages/newHome'
import { CallDetails } from 'pages/CallDetails'
import { Legacy, CinesApp } from 'pages/Legacy'
import { Landing } from 'pages/Landing'
import { CallApplication } from 'pages/newCallApplication'
import { CallApplications } from 'pages/newCallApplications'
import { PrivateRoute } from './PrivateRoute'
import { ValidateEmail } from 'pages/Landing/views/Validate/ValidateEmail'
import { ValidateRegister } from 'pages/Landing/views/Validate/ValidateRegister'
import { InvitedUser } from 'pages/Landing/views/InvitedUser'
import { Dashboard } from 'pages/Dashboard'

// Custom Sentry Route component to allow parameterized transaction names in error reporting
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const DashRoutes: React.FC<{isMobile?: boolean}> = ({ isMobile }) => (
  <SentryRoutes>
    <Route path={Routing.CALL(':id')} element={<CallDetails />} />
    <Route path={Routing.APPLICATION(':uid')} element={<PrivateRoute><CallApplication isMobile={isMobile} /></PrivateRoute>} />
    <Route path={Routing.APPLICATIONS} element={<PrivateRoute><CallApplications isMobile={isMobile} /></PrivateRoute>}/>
    { /* TODO: Remove this when User Dashboard is ready */ }
    {!__PROD__ && 
      <Route path={Routing.DASHBOARD} element={<PrivateRoute><Dashboard isMobile={isMobile} /></PrivateRoute>}/>
    }
    <Route path={Routing.PROFILE} element={<PrivateRoute><Profile isMobile={isMobile} /></PrivateRoute>} />
    <Route path={Routing.LEGACY} element={<PrivateRoute><Legacy /></PrivateRoute>} />
    <Route path={Routing.CINES(':id')} element={<PrivateRoute><CinesApp /></PrivateRoute>} />
    <Route path={Routing.HOME} element={<Home />} />
    <Route path='*' element={<NotFound404 />} />
  </SentryRoutes>
)

export const routes = () => (
  <SentryRoutes>
    <Route path={Routing.REGISTER_VERIFY} element={<ValidateRegister />} />
    <Route path={Routing.EMAIL_VERIFY} element={<ValidateEmail />} />
    <Route path={Routing.REGISTER_CONFIRMATION} element={<Confirmation />} />
    <Route path={Routing.EMAIL_CONFIRMATION} element={<Confirmation email />} />
    <Route path={Routing.LOGIN} element={<Landing />} />
    <Route path={Routing.REGISTER} element={<Landing />} />
    <Route path={Routing.INVITED_PASSWORD_SENT} element={<InvitedUser />} />
    <Route path={Routing.INVITED_PASSWORD} element={<InvitedUser />} />
    <Route path={Routing.FORGOT_PASSWORD_RESET} element={<ForgotPassword />} />
    <Route path={Routing.FORGOT_PASSWORD} element={<ForgotPassword />} />
    <Route path={Routing.FORGOT_PASSWORD_SENT} element={<ForgotPassword />} />
    <Route path='*' element={<NotFound404 />} />
  </SentryRoutes>
)
