import styled from 'styled-components'
import Splash from 'assets/splash.svg'
import { Link } from 'react-router-dom'
import { $Badge, Grid, SvgIcon } from 'prace-common-components'

export const $Home = styled.div`
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 25px;
  font-family: roboto-light, sans-serif;
  width: 100%;
`

export const $ApplyNotice = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`

export const $HeaderBack = styled.div`
  width: 100%;
  background-color: #FBFBFB;
`

export const $Header = styled.div`
  width: 100%;
  color: #333333;
  font-weight: 300;
  font-size: 40px;
  margin: 0 auto;
  padding: 42px 30px;
`

export const $Content = styled.div`
  padding: 0px 25px;
  min-width: 400px;
`

export const $ImgContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-image: url(${Splash});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export const $NoticeTitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
`

export const $NoticeItem = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const $Chip = styled($Badge)`
  margin: 0;
  max-height: 25px;
  border-radius: 15px;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
`

export const $ChipContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .simplebar-content-wrapper {
    display: flex;
    align-items: center;
  }
`

export const $RowContainer = styled.div`
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  >* {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
`

export const $Link = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
`

export const $TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const $SvgIcon = styled(SvgIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const $TopContainer = styled(Grid)`
  &.MuiGrid-root {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`

export const $ActionRow = styled.div<{ expired: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  ${({ expired, theme }) => expired && `
    color: ${theme.colors.text.label};
  `}
`

export const $ActionSvgIcon = styled(SvgIcon)<{ primary?: boolean; expired: boolean }>`
  color: ${({ theme }) => theme.colors.white}; 
  border-radius: 50%;
  padding: 4px;
  background-color: ${({ theme, expired }) => expired ? theme.colors.text.label : theme.colors.secondary};
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const $RowText = styled.div<{ highlight?: boolean; expired: boolean }>`
  ${({ highlight, expired, theme }) => (highlight || expired) && `
    color: ${expired ? theme.colors.text.label : theme.colors.feedback.error};
  `}
`
