import { ApplicationDocument, AssignmentData, FlowApplication, FlowAssignment, FlowCall, OptionGroup, withLoading } from 'prace-common-components'
import { clientApi } from '..'
import { AcceptAssignmentApiPayload, AssignApiPayload, DashboardApiPayload, DashboardResponse, DeleteUploadApiPayload, FlowApplicationState, GetApplicationsResponse, ListOptionsApiPayload, ListOptionsResponse, SaveAssignmentApiPayload, SearchApplicationsApiPayload, UpdateDeadlineApiPayload, UpdateLeadAssignmentApiPayload, UploadApplicationFileApiPayload } from './types'

export const applicationsApi = clientApi.injectEndpoints({
  endpoints: (build) => ({
    createApplication: build.mutation<FlowApplicationState, { callId: FlowCall['id'] }>({
      ...withLoading,
      query: (body) => ({
        url: '/v2/applications',
        method: 'POST',
        body,
      }),
    }),
    duplicateApplication: build.mutation<FlowApplicationState, FlowCall['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/applications/duplicate/${id}`,
        method: 'POST',
      }),
    }),
    assign: build.mutation<FlowAssignment, AssignApiPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/applications/${id}/assign`,
        method: 'POST',
        body,
      }),
    }),
    updateLeadAssignment: build.mutation<FlowApplicationState, UpdateLeadAssignmentApiPayload>({
      ...withLoading,
      query: ({ applicationId, assignmentId, ...body }) => ({
        url: `/v2/applications/${applicationId}/assignments/${assignmentId}/lead`,
        method: 'PATCH',
        body,
      }),
    }),
    searchApplications: build.mutation<GetApplicationsResponse, SearchApplicationsApiPayload>({
      ...withLoading,
      query: ({ limit, offset, ...body }) => ({
        url: `/v2/applications/search?limit=${limit}&offset=${offset}`,
        method: 'POST',
        body,
        keepUnusedDataFor: 150, /* 2.5 minutes cache */ 
      }),
    }),
    getDashboard: build.mutation<DashboardResponse, DashboardApiPayload>({
      ...withLoading,
      query: ({ limit, offset, ...body }) => ({
        url: `/v2/applications/dashboard?limit=${limit}&offset=${offset}`,
        method: 'POST',
        body,
        keepUnusedDataFor: 150, /* 2.5 minutes cache */ 
      }),
    }),
    getApplication: build.query<FlowApplication, FlowApplication['uid']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/applications/${id}` }),
    }),
    getDocuments: build.query<ApplicationDocument[], FlowApplication['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/applications/${id}/documents` }),
    }),
    deleteApplication: build.mutation<void, FlowApplication['id']>({
      ...withLoading,
      query: (id) => ({
        url: `/v2/applications/${id}`,
        method: 'DELETE',
      }),
    }),
    uploadFile: build.mutation<AssignmentData, UploadApplicationFileApiPayload>({
      ...withLoading,
      query: ({ id, data }) => ({
        url: `/v2/assignments/${id}/upload`,
        method: 'POST',
        body: data,
        formData: true,
      }),
    }),
    updateDeadline: build.mutation<void, UpdateDeadlineApiPayload>({
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/deadline`,
        method: 'PUT',
        body,
      }),
    }),
    deleteUpload: build.mutation<AssignmentData, DeleteUploadApiPayload>({
      query: ({ id, uploadId }) => ({
        url: `/v2/assignments/${id}/upload/${uploadId}`,
        method: 'DELETE',
      }),
    }),
    saveAssignment: build.mutation<AssignmentData[], SaveAssignmentApiPayload>({
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/save`,
        method: 'PUT',
        body,
      }),
    }),
    submitAssignment: build.mutation<void, SaveAssignmentApiPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/submit`,
        method: 'PUT',
        body,
      }),
    }),
    deleteAssignmentData: build.mutation<void, {id: number; dataIds: number[]}>({
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/data`,
        method: 'DELETE',
        body,
      }),
    }),
    acceptAssignment: build.mutation<void, AcceptAssignmentApiPayload>({
      ...withLoading,
      query: ({ id, ...body }) => ({
        url: `/v2/assignments/${id}/accept`,
        method: 'PUT',
        body,
      }),
    }),
    listOptions: build.query<ListOptionsResponse, ListOptionsApiPayload>({
      ...withLoading,
      query: ({ limit = 999, offset = 0 }) => ({ url: `/v2/options?id=130&offset=${offset}&limit=${limit}` }),
      keepUnusedDataFor: 1800, /* 30 minutes cache */
    }),
    getOptions: build.query<OptionGroup, OptionGroup['id']>({
      ...withLoading,
      query: (id) => ({ url: `/v2/options/${id}` }),
      keepUnusedDataFor: 1800, /* 30 minutes cache */
    }),
  }),
})

export const {
  useCreateApplicationMutation,
  useDuplicateApplicationMutation,
  useAssignMutation,
  useUpdateLeadAssignmentMutation,
  useSearchApplicationsMutation,
  useGetDashboardMutation,
  useGetApplicationQuery,
  useGetDocumentsQuery,
  useDeleteApplicationMutation,
  useUploadFileMutation,
  useUpdateDeadlineMutation,
  useDeleteUploadMutation,
  useSaveAssignmentMutation,
  useSubmitAssignmentMutation,
  useDeleteAssignmentDataMutation,
  useAcceptAssignmentMutation,
  useListOptionsQuery,
  useGetOptionsQuery,
} = applicationsApi
